import React from 'react';

import styles from './MainLayoutComp.module.css';

import strynDevLogo from '../../assets/stryndevlogonew.svg';

const MainLayoutComp = (props) => {

    return (<div className={styles.mainLayoutCompContainer}>
        <div className={styles.greyBoxContainer}>
            <img className={styles.logoStyle} src={strynDevLogo}>
            </img>
            {/* <span className={styles.textStyle}>StrynDev Technology</span> */}
        </div>
    </div>)
}

export default MainLayoutComp;