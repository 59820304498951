import './App.css';

import MainLayoutComp from './components/MainLayoutComp/MainLayoutComp';

function App() {
  return (
    <MainLayoutComp></MainLayoutComp>
  );
}

export default App;
